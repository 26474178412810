<template>
  <f7-page class="scan-page">
    <f7-navbar back-link="Back"> </f7-navbar>

    <meta name="robots" content="noindex" />

    <section class="scan-container">
      <div class="container">
        <div v-if="!hasCamera" class="floating-actions">
          <f7-button fill large @click="selectImage($refs)">
            <font-awesome-icon :icon="['fal', 'barcode-read']" fixed-width />
          </f7-button>
        </div>

        <video id="qrVideo"></video>

        <input ref="fileInput" capture="environment" type="file" accept="image/*" @change="loadImage($event)" />
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";

import QrScanner from "qr-scanner";
import QrScannerWorkerPath from "!!file-loader!../../../node_modules/qr-scanner/qr-scanner-worker.min.js";
QrScanner.WORKER_PATH = QrScannerWorkerPath;

export default defineComponent({
  name: "ScanPage",
  components: {},
  props: { f7router: Object },
  setup(props) {
    const hasCamera = ref(false);
    const qrScanner = ref(false);

    const scanResult = (data) => {
      if (data) props.f7router.navigate({ name: "productViewPage", params: { code: data } });
      if (hasCamera.value) qrScanner.value.stop();
    };

    const scanError = (error) => {};

    const loadImage = (event) => {
      let input = event.target;

      if (input?.files?.[0]) {
        let reader = new FileReader();

        reader.onload = (e) => {
          if (e?.target?.result) {
            QrScanner.scanImage(e?.target?.result).then((result) => {
              if (result) props.f7router.navigate({ name: "productViewPage", params: { code: result } });
            });
          }
        };

        reader.readAsDataURL(input.files[0]);
      }
    };

    const selectImage = (refs) => {
      refs.fileInput.value = "";
      refs.fileInput.click();
    };

    const scanCode = async () => {
      let video = document.getElementById("qrVideo");
      qrScanner.value = new QrScanner(video, scanResult, scanError);

      hasCamera.value = await QrScanner.hasCamera();

      if (hasCamera.value) {
        qrScanner.value.start();
      }
    };

    onMounted(scanCode);

    return { hasCamera, scanCode, loadImage, selectImage };
  },
});
</script>

<style scoped>
video {
  width: 100%;
  min-height: 300px;
  height: 100vh;
}
input[type="file"] {
  display: none;
}
</style>
